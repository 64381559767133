import React, { useState, useEffect } from 'react'
import MLCOriginal from '../../Assets/yandes.svg'
import { IonIcon } from "react-ion-icon";
import { AiOutlineHome, AiOutlineLogout } from "react-icons/ai";
import { FiBox, FiSettings } from "react-icons/fi";
import { PiUserSwitchThin } from 'react-icons/pi'
// eslint-disable-next-line
import { Link, useNavigate } from 'react-router-dom';
import Aos from 'aos'
import "aos/dist/aos.css"
import { BsCreditCard } from 'react-icons/bs';

const Navbar = ({ onRouteSelect, selected, setselected }) => {

  const navigate = useNavigate()


  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);


  const logout = () => {
    localStorage.clear()
    navigate('/')
  }
  return (
    //Container for Navbar
    <div className=' bg-white justify-between items-center pl-6 pr-3  text-black font-raleway md:py-8 py-2 border-r border-[#f2f2f2] h-screen'>
      {/*Logo*/}
      <span className='flex font-poppins font-bold cursor-pointer items-center space-x-4'>
        <Link to={'/'}>
          <img
            className='text-center w-7'
            src={MLCOriginal}
            alt='logo'
          />

        </Link>
        <h1 className='font-grifter text-base mt-1'>Yandes</h1>
      </span>
      {/*Logo*/}

      {/*NavLinks*/}
      <ul className={`mt-8 space-y-4 pt-8 font-aeonikregular font-semibold text-sm transition-all duration-500 ease-in border-t border-[#f2f2f2]`}>
        {/*Overview*/}
        <div
          onClick={() => (setselected(0), onRouteSelect('dashboard-overview'))}
          className={selected === 0 ?
            "bg-[#eef2ff] flex items-center justify-between px-6 py-3 border-l-4 border-[#2752E7] cursor-pointer" :
            "flex items-center justify-between px-6 py-3 cursor-pointer hover:bg-[#eef2ff]"}>
          {/*Icon and Text*/}
          <div className='flex items-center space-x-4'>
            <AiOutlineHome color={selected === 0 ? "#2752E7" : "#425466"} strokeWidth={2} size={22} />
            <h1 className={selected === 0 ?
              "text-sm font-aeonikmedium text-[#2752E7]" :
              "text-sm font-aeonikmedium text-[#425466]"}>Overview</h1>
          </div>
          {/*Icon and Text*/}
          {/*Badge or Number Notification*/}
          <span className='bg-[#EFEFF1] rounded-full h-5 hidden //inline-flex items-center justify-center px-2'>
            <h1 className='text-xs font-aeonikmedium'>15,689</h1>
          </span>
          {/*Badge or Number Notification*/}
        </div>
        {/*Overview*/}

        {/*Services*/}
        <div
          onClick={() => (setselected(1), onRouteSelect('dashboard-services'))}
          className={selected === 1 ?
            "bg-[#eef2ff] flex items-center justify-between px-6 py-3 border-l-4 border-[#2752E7] cursor-pointer" :
            "flex items-center justify-between px-6 py-3 cursor-pointer hover:bg-[#eef2ff]"}>
          {/*Icon and Text*/}
          <div className='flex items-center space-x-4'>
            <FiBox color={selected === 1 ? "#2752E7" : "#425466"} radius={24} size={22} />
            <h1 className={selected === 1 ?
              "text-sm font-aeonikmedium text-[#2752E7]" :
              "text-sm font-aeonikmedium text-[#425466]"}>Services</h1>
          </div>
          {/*Icon and Text*/}
          {/*Badge or Number Notification Note the Inline Flex Commented out for when not selected*/}
          <span className='bg-[#EFEFF1] rounded-full h-5 //inline-flex hidden items-center justify-center px-2'>
            <h1 className='text-xs font-aeonikmedium'>15,689</h1>
          </span>
          {/*Badge or Number Notification*/}
        </div>
        {/*Services*/}

          {/*Payments*/}
          <div
          onClick={() => (setselected(2), onRouteSelect('dashboard-payments'))}
          className={selected === 2 ?
            "bg-[#eef2ff] items-center flex justify-between px-6 py-3 border-l-4 border-[#2752E7] cursor-pointer" :
            "hidden items-center justify-between px-6 py-3 cursor-pointer hover:bg-[#eef2ff]"}>
          {/*Icon and Text*/}
          <div className='flex items-center space-x-4'>
            <BsCreditCard color={selected === 2 ? "#2752E7" : "#425466"} radius={24} size={22} />
            <h1 className={selected === 2 ?
              "text-sm font-aeonikmedium text-[#2752E7]" :
              "text-sm font-aeonikmedium text-[#425466]"}>Payments</h1>
          </div>
          {/*Icon and Text*/}
          {/*Badge or Number Notification Note the Inline Flex Commented out for when not selected*/}
          <span className='bg-[#EFEFF1] rounded-full h-5 //inline-flex hidden items-center justify-center px-2'>
            <h1 className='text-xs font-aeonikmedium'>15,689</h1>
          </span>
          {/*Badge or Number Notification*/}
        </div>
        {/*Payments*/}

           {/*  Customers*/}
           <div
          onClick={() => (setselected(3), onRouteSelect('dashboard-users'))}
          className={selected === 3 ?
            "bg-[#eef2ff] flex items-center justify-between px-6 py-3 border-l-4 border-[#2752E7] cursor-pointer" :
            "hidden items-center justify-between px-6 py-3 cursor-pointer hover:bg-[#eef2ff]"}>
          {/*Icon and Text*/}
          <div className='flex items-center space-x-4'>
            <PiUserSwitchThin color={selected === 3 ? "#2752E7" : "#425466"} radius={24} size={22} />
            <h1 className={selected === 3 ?
              "text-sm font-aeonikmedium text-[#2752E7]" :
              "text-sm font-aeonikmedium text-[#425466]"}>Customers</h1>
          </div>
          {/*Icon and Text*/}
          {/*Badge or Number Notification Note the Inline Flex Commented out for when not selected*/}
          <span className='bg-[#EFEFF1] rounded-full h-5 //inline-flex hidden items-center justify-center px-2'>
            <h1 className='text-xs font-aeonikmedium'>15,689</h1>
          </span>
          {/*Badge or Number Notification*/}
        </div>
        {/* Customers*/}

        {/*Set Section Divider for different in App sections */}
        <h1 className='text-xs px-6 font-aeonikregular text-[#B5B5B5] pt-4 pb-1'>SYSYTEM SETTINGS</h1>
        {/*Set Section Divider for different in App sections */}

        {/*Services*/}
        <div
          onClick={() => (setselected(8), onRouteSelect('dashboard-settings'))}
          className={selected === 8 ?
            "bg-[#eef2ff] flex items-center justify-between px-6 py-3 border-l-4 border-[#2752E7] cursor-pointer" :
            "hidden items-center justify-between px-6 py-3 cursor-pointer hover:bg-[#eef2ff]"}>
          {/*Icon and Text*/}
          <div className='flex items-center space-x-4'>
            <FiSettings color={selected === 8 ? "#2752E7" : "#425466"} radius={24} size={22} />
            <h1 className={selected === 8 ?
              "text-sm font-aeonikmedium text-[#2752E7]" :
              "text-sm font-aeonikmedium text-[#425466]"}>Settings</h1>
          </div>
          {/*Icon and Text*/}
          {/*Badge or Number Notification Note the Inline Flex Commented out for when not selected*/}
          <span className='bg-[#EFEFF1] rounded-full h-5 //inline-flex hidden items-center justify-center px-2'>
            <h1 className='text-xs font-aeonikmedium'>15,689</h1>
          </span>
          {/*Badge or Number Notification*/}
        </div>
        {/*Services*/}
      </ul>
      {/*NavLinks*/}


      {/*Buttons*/}
      <div className='flex mt-2 md:mr-10 items-center font-gilroysemibold text-sm'>
        <div
          onClick={logout}
          className={selected === 20 ?
            "bg-[#eef2ff] flex items-center justify-between px-6 py-3 border-l-4 border-[#2752E7] cursor-pointer" :
            "flex items-center justify-between px-6 py-3 cursor-pointer hover:bg-[#eef2ff]"}>
          {/*Icon and Text*/}
          <div className='flex items-center space-x-4'>
            <AiOutlineLogout color={selected === 20 ? "#2752E7" : "#425466"} radius={24} size={22} />
            <h1 className={selected === 20 ?
              "text-sm font-aeonikmedium text-[#2752E7]" :
              "text-sm font-aeonikmedium text-[#425466]"}>Logout </h1>
          </div>
          {/*Icon and Text*/}
          {/*Badge or Number Notification Note the Inline Flex Commented out for when not selected*/}
          <span className='bg-[#EFEFF1] rounded-full h-5 //inline-flex hidden items-center justify-center px-2'>
            <h1 className='text-xs font-aeonikmedium'>15,689</h1>
          </span>
          {/*Badge or Number Notification*/}
        </div>
      </div>
      {/*Buttons*/}
    </div>
    //Container for Navbar
  )
}

export default Navbar