import React, { useState, useEffect } from 'react'
import TableComponent from './ReUsableComponents/TableComponent'

import useFormatDate from './Hooks/useFormatDate'

function DashboardCustomers() {
    const [customers, setcustomers] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const formatDate = useFormatDate();

    useEffect(() => {
        const fetchTransactions = async () => {
            setIsLoading(true);
            // Get the "user" object from local storage
            const userJson = localStorage.getItem('user');

            // If the user object is not found or doesn't have a token, log an error
            if (!userJson) {
                console.error('User object not found in local storage');
                setIsLoading(false);
                return;
            }

            const user = JSON.parse(userJson);
            const token = user.token;

            if (!token) {
                console.error('Token not found in user object');
                setIsLoading(false);
                return;
            }

            try {
                const response = await fetch('', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setcustomers(data);
                setIsLoading(false);
            } catch (err) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchTransactions();
    }, []);

    return (
        <div>
            <div className='mx-6'>
                <TableComponent
                    headerName="Majorlink Customers"
                    filters={[
                       
                    ]}

                    columns={[
                        {
                            key: 'name',
                            label: 'Full Name',
                            render: (item) => (
                                <span className='flex items-center space-x-2' style={{ color: '#262626' }}>
                                    <h1>
                                        {item.firstname || "N/A"}  {item.lastname || "N/A"}
                                    </h1>
                                </span>
                            )
                        },
                        {
                            key: 'email',
                            label: 'Email',
                            render: (item) => (
                                <span style={{ color: '#262626' }}>
                                  {item.email}
                                </span>
                            )
                        },
                        {
                            key: 'phone',
                            label: 'Phone Number',
                            render: (item) => <span style={{ color: '#262626' }}>{item.phonenumber || "N/A"}</span>
                        },
                        {
                            key: 'status',
                            label: 'User Address',
                            render: (item) => <span style={{ color: '#262626' }}>{item.houseaddress || "N/A"}</span>
                        },
                        {
                            key: 'created date',
                            label: 'Creation Date',
                            render: (item) => (
                                <span style={{ color: '#262626' }}>
                                    {item.created_at ? formatDate(item.created_at) : "N/A"}
                                </span>
                            )
                        },
                        {
                            key: 'action',
                            label: '',
                            render: (item) => <span className='underline' style={{ color: '#262626' }}>View</span>
                            // Assuming "View" is always available
                        },
                        // ... add other columns as needed
                    ]}

                    data={customers}
                    isLoading={isLoading}
                    error={error}
                //onSelectRow={(item) => (onidselect(item), select(4))}
                />

            </div>
        </div>
    )
}

export default DashboardCustomers