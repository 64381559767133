import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast';
import {
  BrowserRouter,
  Routes,
  Route, Navigate
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import ErrorPage from './Pages/ErrorPage';
import AdminLogin from './Pages/Admin/AdminLogin';
import AdminDashboard from './Pages/Admin/AdminDashboard';
import Unauthorized from './Pages/Unauthorized';

import 'react-toastify/dist/ReactToastify.css';

function App() {

  const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('user');
    if (!token) {
      return <Navigate to="/" />;
    }
    return children;
  };


  return (
    <div className='font-aeonikmedium'>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path='/' element={<AdminLogin />}></Route>
          {/*Protected Route*/}
          <Route path='/admin/*' element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          } />
          {/*Protected Route*/}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Toaster />
      </BrowserRouter>
    </div>
  );
}

export default App;
